@use 'statistics-poc';
@use 'infobubbles';
@use 'antd/dist/antd';

body {
  font-family: 'Roboto', 'Arial', 'sans-serif';
}

.ant-picker-shadow {
  &-2 {
    .ant-picker-panel-container {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
  }
}

.ant-menu-submenu-title {
  padding-left: 12px !important;
  color: #FFF;
}

.bolded {
  font-weight: bold;
}

body .ant-layout {
  .ant-menu-submenu-title, .ant-menu-item-only-child{
    padding-left: 5px !important;
  }

  .ant-menu-sub {
    padding-left: 29px;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #1d2e54;
  }

  .ant-menu-item-selected {
    font-weight: bold;
    background: transparent !important;
    border-right: 4px solid #FFF !important;
  }
}

.disabled {
  color: gray;
}
